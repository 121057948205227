/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://msevents.microsoft.com/event?id=1595073679"
  }, "Microsoft Ignite Spotlight on Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/MicrosoftKorea/status/1590932339256877059"
  }, "Microsoft Ignite Spotlight on Korea - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/Outsideris/status/1592373505139802112"
  }, "Outsider on Twitter: Microsoft CEO 사티아 나델라 처음 봤다. - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/1634"
  }, "Microsoft Ignite Spotlight on Korea의 GitHub Copilot 발표 자료")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://openai.com/"
  }, "OpenAI")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.goorm.io/commit_2nd/"
  }, "성장: 개발자들의 평생 과제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ide.goorm.io/"
  }, "구름IDE")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://edu.goorm.io/"
  }, "구름EDU")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/steveruizok/status/1592870019717038081"
  }, "Steve Ruiz on Twitter: leaked photo a Tweep friend, elon's first PR - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mastodon.social/explore"
  }, "마스토돈 mastodon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twingyeo.kr/explore"
  }, "트잉여 Twingyeo 마스토돈")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://podcast.44bits.io/episodes/28"
  }, "마스토돈 언급 44bits 팟캐스트 28회")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://seoulpi.co.kr/40056/"
  }, "끝은 새로운 시작, 판교 알파돔시티 - 서울프라퍼티인사이트 SPI")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
